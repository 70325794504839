export const columns = [
  {
    id: 'device',
    label: 'Device name',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'coin',
    label: 'Coin',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => props.currency,
  },
  {
    id: 'hashrate',
    label: 'Hashrate, TH/s',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'electricity',
    label: 'Power, Watt',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
];
