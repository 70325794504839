import { Box, Grid } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { LiveUtcDateTime } from 'components/LiveUtcDateTime';
import { BitcoinInfoCard } from 'components/Cards/BitcoinInfoCard';
import { ChainCard } from './ChainCard';
import { threeRowGridItem } from '../styles';

export const Chain = (props: any) => {
  const { data, currency, currencyValue, coinAbb } = props;

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionHeading
          sx={{
            color: '#fff',
          }}
          name={`${currency} Chain`}
        />
        <LiveUtcDateTime />
      </Box>
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={2}>
        <Grid {...threeRowGridItem}>
          <BitcoinInfoCard
            showFilter
            styles={{
              height: '550px',
            }}
            subtitle="Revenue"
            title=""
            coinAbb={coinAbb}
            currency={currency}
            currencyValue={currencyValue}
          />
        </Grid>
        <>
          <Grid {...threeRowGridItem}>
            <ChainCard currency={currency} type="revenue" data={data} />
          </Grid>
          <Grid {...threeRowGridItem}>
            <ChainCard currency={currency} type="difficulty" data={data} />
          </Grid>
        </>
      </Grid>
    </>
  );
};
