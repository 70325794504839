import { Box, Button, Typography } from '@mui/material';
import { buttonSection, okButton, warningModal } from './styles';
import DeleteIcon from 'assets/images/deleteIcon.svg';

interface WarningModalProps {
  onCancel: () => void;
  devices: any[];
}

export const WarningModal = ({
  onCancel,
  devices,
}: WarningModalProps): JSX.Element => {
  return (
    <Box sx={warningModal}>
      <Box sx={{ marginBottom: '30px' }}>
        <img className="warningIcon" src={DeleteIcon} alt="logo" />
      </Box>
      <Typography sx={{ marginBottom: '20px' }}>
        Please, remove association with the following hardware to delete this
        hosting opportunity:
      </Typography>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: 'fit-content' }}>
          {devices.map((device: any, index: number) => (
            <Typography
              key={index}
              sx={{ textAlign: 'start', marginBottom: '5px' }}
            >
              - {device.hardware.device}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box sx={buttonSection}>
        <Button onClick={onCancel} sx={okButton}>
          Ok
        </Button>
      </Box>
    </Box>
  );
};
