import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Box, Typography } from '@mui/material';
import { ContactSalesButton } from '../BaseTable/ContactSalesButton';
import { colors } from 'shared/constants/theme';

const containerBold = {
  fontWeight: 600,
};

const alignCenter = {
  width: '100%',
  textAlign: 'center',
};

const MissingCell = () => <Box sx={[containerBold, alignCenter]}>-</Box>;

export const getColumns = (): ColumnsType[] => [
  {
    id: 'coin',
    label: 'Coin',
    minWidth: 150,
    align: 'left',
    sortable: false,
    expandableIcon: false,
    render: (row) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '10px',
          marginLeft: '20px',
        }}
      >
        <Box>
          <img
            src={row.logo}
            alt={row.coin}
            style={{ width: '40px', height: '40px' }}
          />
        </Box>
        <Box>
          <Typography sx={{ containerBold }}>{row.coinName}</Typography>
          <Typography
            sx={{ fontWeight: 300, color: colors.grey, fontSize: '12px' }}
          >
            {row.coinAbb}
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    id: 'totalMachines',
    label: 'Total Miners',
    minWidth: 130,
    align: 'center',
    sortable: false,
    render: () => <MissingCell />,
  },
  {
    id: 'totalHashrate',
    label: 'Total Hashrate',
    sortable: false,
    minWidth: 140,
    align: 'center',
    render: () => <MissingCell />,
  },
  {
    id: 'totalMinedCoins',
    label: 'Total Mined',
    minWidth: 120,
    align: 'center',
    sortable: false,
    render: () => <MissingCell />,
  },
  {
    id: 'details',
    label: 'Details',
    minWidth: 150,
    align: 'left',
    sortable: false,
    render: (row) => <ContactSalesButton row={row} />,
  },
];
