import { Box, Typography } from '@mui/material';
import { modalContainer, textStyles, mailStyles, titleStyles } from './styles';

export const ContactSalesModal = (props: any): JSX.Element => {
  const { coinName, coinLogo } = props;

  return (
    <Box sx={modalContainer}>
      <Typography sx={titleStyles}>{coinName} mining</Typography>
      <img src={coinLogo} width={60} height={60} />
      <Typography sx={textStyles}>
        Please, contacts our sales team to start Litecoin mining at the
        following email address:
        <Typography
          component="a"
          href="mailto:contact@hearstcorporation.io"
          sx={mailStyles}
        >
          contact@hearstcorporation.io
        </Typography>
      </Typography>
    </Box>
  );
};
