import { styled } from '@mui/material/styles';
import { TableCell, TableRow, tableCellClasses } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: `${colors.lightGreen} !important`,
    color: `${colors.lightGrey} !important`,
    marginBottom: '10px',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    margin: '20px 0 !important',
    color: '#131613 !important',
  },
}));
