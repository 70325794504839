import { colors } from 'shared/constants/theme';

export const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  title: { color: colors.black, fontSize: '48px' },
  subtitle: { color: colors.black, fontSize: '24px', marginTop: '16px' },
  buttons: {
    display: 'flex',
    gap: '24px',
    marginTop: '42px',
  },
  boxButton: {
    color: colors.black,
    fontSize: '24px',
    background: 'rgb(227 242 235)',
    width: '218px',
    height: '180px',
    border: '0.5px solid rgba(255, 255, 255, .5)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.lightGreen,
    },
  },
  boxButtonActive: {
    backgroundColor: colors.lightGreen,
    color: 'white',
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
};
