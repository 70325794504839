import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { IconButtonStyled } from 'components/Tables/RedesignedTable/components/RedesignedTableRow/styles';

export const ExpandArrow = ({ open, setOpen }: any) => {
  return (
    <IconButtonStyled
      aria-label="expand row"
      size="small"
      onClick={() => setOpen((prev: boolean) => !prev)}
    >
      {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
    </IconButtonStyled>
  );
};
