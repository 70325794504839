import TableHead from '@mui/material/TableHead';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { EnhancedTableHead } from 'components/Tables/RedesignedTable/SortableTableHead';
import {
  StyledTableCell,
  StyledTableRow,
} from 'components/Tables/RedesignedTable/styles';
import { colors } from 'shared/constants/theme';
import { TableHeaderOptions } from 'shared/utils/enum/tableEnums';

type Props = {
  show?: boolean;
  showEnd?: boolean;
  columns: ColumnsType[];
  order: 'asc' | 'desc';
  orderBy: any;
  setOrder: any;
  setOrderBy: any;
  headerOption?: any;
  headerAction?: any;
  rowData?: any;
};
export const RedesignedTableHead = ({
  columns,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  show,
  showEnd,
  headerOption,
  headerAction,
  rowData,
}: Props) => {
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead
      className="tableHead"
      sx={{
        backgroundColor:
          headerOption === TableHeaderOptions.LIGHT
            ? colors.white
            : colors.lightGreen,
      }}
    >
      <StyledTableRow>
        {show && (
          <StyledTableCell
            align="center"
            style={{
              minWidth: 100,
              fontSize: '14px',
              fontWeight: '600',
              borderBottom: '1px solid #E0E0E0',
              color:
                headerOption === TableHeaderOptions.LIGHT
                  ? colors.lightGreen
                  : colors.white,
              backgroundColor:
                headerOption === TableHeaderOptions.LIGHT
                  ? colors.white
                  : colors.lightGreen,
            }}
          >
            {headerAction ? headerAction(rowData) : 'Actions'}
          </StyledTableCell>
        )}
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
          headerOption={headerOption}
        />
        {showEnd && (
          <StyledTableCell
            align="center"
            style={{
              minWidth: 100,
              fontSize: '14px',
              fontWeight: '600',
              borderBottom: '1px solid #E0E0E0',
              color:
                headerOption === TableHeaderOptions.LIGHT
                  ? colors.lightGreen
                  : colors.white,
              backgroundColor:
                headerOption === TableHeaderOptions.LIGHT
                  ? colors.white
                  : colors.lightGreen,
            }}
          >
            {headerAction ? headerAction(rowData) : 'Actions'}
          </StyledTableCell>
        )}
      </StyledTableRow>
    </TableHead>
  );
};
