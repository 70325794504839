import { Box, TableCell } from '@mui/material';
import editIcon from 'assets/images/editIcon.svg';
import removeIcon from 'assets/images/removeIcon.svg';

export const TableActions = ({
  handleEdit,
  row,
  rowData,
  onRowDelete,
}: any) => {
  return (
    <TableCell align="center" sx={{ minWidth: '130px', flexWrap: 'nowrap' }}>
      {handleEdit && (
        <Box
          onClick={() => handleEdit(row, rowData)}
          component="img"
          sx={{
            cursor: 'pointer',
            marginRight: '10px',
            height: 50,
            width: 50,
            maxHeight: { xs: 25, md: 25 },
            maxWidth: { xs: 25, md: 25 },
          }}
          src={editIcon}
        />
      )}
      {onRowDelete && (
        <Box
          onClick={() => onRowDelete(row.id)}
          component="img"
          sx={{
            cursor: 'pointer',
            marginRight: '10px',
            height: 50,
            width: 50,
            maxHeight: { xs: 25, md: 25 },
            maxWidth: { xs: 25, md: 25 },
          }}
          src={removeIcon}
        />
      )}
    </TableCell>
  );
};
