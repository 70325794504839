import HttpService from './http.service';

class ElectricityInvoicesService {
  endpoint: string = 'electricity';

  getEletricityInvoices = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);
}

export default new ElectricityInvoicesService();
