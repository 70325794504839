import { Typography, Box } from '@mui/material';
import { SeeMoreButton } from '../BaseTable/SeeMoreButton';
import { DownloadButton } from './cells';
import { downloadFile } from 'shared/utils/fileDownloader';
import { colors } from 'shared/constants/theme';
import { handleCoinAbb } from 'shared/utils/currency';

const tableTextStyles = {
  color: colors.darkBlue,
  fontWeight: '600',
  fontSize: '14px',
};
const tableSubTextStyles = {
  color: '#AEAEAE',
  fontWeight: '500',
  fontSize: '14px',
};

const getDate = (date: string) => {
  const dateObj = new Date(date);
  return `${String(dateObj.getDate()).padStart(2, '0')}.${String(
    dateObj.getMonth() + 1,
  ).padStart(2, '0')}.${dateObj.getFullYear()}`;
};

const getTime = (date: string) => {
  const dateObj = new Date(date);
  return `${String(dateObj.getHours()).padStart(2, '0')}:${String(
    dateObj.getMinutes(),
  ).padStart(2, '0')}`;
};

export const columns = (setPreviewSimulationId: any, setShowEditModal: any) => {
  return [
    {
      id: 'clientName',
      label: 'Prospect',
      minWidth: 100,
      align: 'left',
      render: (simulation: any) => {
        return (
          <Box>
            <Typography sx={tableTextStyles}>
              {simulation.clientName}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'coin',
      label: 'Coin',
      minWidth: 100,
      align: 'left',
      render: (simulation: any) => {
        return (
          <Box>
            <Typography sx={tableTextStyles}>
              {handleCoinAbb(simulation.currency)}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'creator',
      label: 'Creator',
      minWidth: 100,
      align: 'left',
      render: (simulation: any) => {
        return (
          <Box>
            <Typography sx={tableTextStyles}>{simulation.creator}</Typography>
          </Box>
        );
      },
    },
    {
      id: 'createdAt',
      label: 'Created date / time',
      minWidth: 100,
      align: 'left',
      render: (simulation: any) => {
        return (
          <Box>
            <Typography sx={tableTextStyles}>
              {getDate(simulation.createdAt)}
            </Typography>
            <Typography sx={tableSubTextStyles}>
              {getTime(simulation.createdAt)}
            </Typography>
          </Box>
        );
      },
    },
    {
      id: 'id',
      label: 'Simulation ID',
      minWidth: 100,
      align: 'left',
      render: (simulation: any) => {
        return (
          <Box>
            <Typography sx={tableTextStyles}>{simulation.id}</Typography>
          </Box>
        );
      },
    },
    {
      label: 'Details',
      sortable: false,
      minWidth: 150,
      align: 'left',
      render: ({ id }: any) => (
        <SeeMoreButton
          onClick={() => {
            setShowEditModal(true);
            setPreviewSimulationId(id);
          }}
        />
      ),
    },
    {
      label: 'Download',
      sortable: false,
      minWidth: 150,
      align: 'left',
      render: ({ pdf }: any) => (
        <DownloadButton
          onClick={() => {
            downloadFile(pdf);
          }}
        />
      ),
    },
  ];
};
