import { CheckboxGroup } from 'components/ControlledInputs/CheckboxGroup';
import { getAdminSupplierFormSchema } from 'components/Forms/AdminSupplierForm/schema';
import { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { SvgComponentSee } from 'components/Icons/See';
import { SvgComponentHide } from 'components/Icons/Hide';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserRole } from 'shared/utils/enum/UserEnum';
import UsersService from 'shared/services/users.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { container, content, formTitle } from '../styles';
import { colors } from 'shared/constants/theme';

export const AdminSupplierForm = ({
  setShowModal,
  editData,
  onFinish,
  setIsLoading,
}: any): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);
  const { showSnackbar } = useNotification();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const schema = getAdminSupplierFormSchema({ password: !editData });

  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors, isDirty },
  } = useForm({ resolver: yupResolver(schema) });
  const isHosting = useWatch({ control, name: 'isHosting' });
  const isHardware = useWatch({ control, name: 'isHardware' });

  const onSubmit: any = async (userData: any): Promise<void> => {
    try {
      setIsLoading(true);
      const payload = {
        ...userData,
        companyName: '',
        role: 'Supplier',
      };

      if (editData) {
        await UsersService.updateUser(editData.id, userData);
        showSnackbar('Successfully Edited', 'success');
      } else {
        if (isHosting) {
          await UsersService.createUser(payload);
          showSnackbar('Successfully Created', 'success');
        } else if (isHardware) {
          await UsersService.createHardwareSupplier(payload);
          showSnackbar('Successfully Created', 'success');
        }
      }
      onFinish();
      setShowModal(false);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>
          {editData ? 'Edit' : 'Add'} Supplier
        </Typography>
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.username ?? ''}
          name="username"
          label="Username"
          error={errors}
          placeholder="Username"
          disableAutocomplete
        />
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.email ?? ''}
          name="email"
          label="Email"
          error={errors}
          placeholder="name.example@gmail.com"
        />
        <ControlledTextField
          control={control}
          defaultValue={editData?.phoneNumber ?? ''}
          name="phoneNumber"
          label="Phone number"
          error={errors}
          placeholder="Phone number"
        />
        <CheckboxGroup
          label="Supplier type"
          required
          control={control}
          error={errors}
          trigger={trigger}
          disabled={!!editData}
          options={[
            {
              name: 'isHardware',
              label: 'Hardware',
              value:
                editData?.role === UserRole.HARDWARE_SUPPLIER ||
                editData?.isHardware,
            },
            {
              name: 'isHosting',
              label: 'Hosting',
              value: editData?.role === UserRole.SUPPLIER,
            },
          ]}
        />

        {!editData?.id && (
          <ControlledTextField
            control={control}
            defaultValue=""
            name="password"
            label="Password"
            placeholder="Password"
            error={errors}
            required
            disableAutocomplete
            type={showPassword ? 'text' : 'password'}
            textFieldProps={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <SvgComponentSee />
                      ) : (
                        <SvgComponentHide />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            sx={{
              marginRight: '10px',
              background: '#eeeeee',
              color: 'black',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#eeeeee',
              },
            }}
            onClick={() => setShowModal(false)}
            type="button"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: colors.lightGreen,
              color: 'white',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#1d4fbb',
              },
            }}
            type="submit"
            disabled={!isDirty && !!editData}
          >
            {editData ? 'Save' : 'Create'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
