import { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNotification } from 'shared/hooks/useNotification';
import * as yup from 'yup';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import AuthService from 'shared/services/auth.service';
import { SvgComponentSee } from 'components/Icons/See';
import { SvgComponentHide } from 'components/Icons/Hide';
import { colors } from 'shared/constants/theme';
import { CustomModal } from 'components/Modals/CustomModal';
import { SaveSuccessModal } from 'components/Modals/SaveSuccessModal';

const schema = yup.object().shape({
  currentPassword: yup
    .string()
    .required('Current password is a required field'),
  newPassword: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('New password is a required field')
    .oneOf([yup.ref('confirmPassword')], 'Passwords do not match'),
  confirmPassword: yup
    .string()
    .required('Confirm password is a required field')
    .min(6)
    .oneOf([yup.ref('newPassword')], 'Passwords do not match'),
});

export const ChangeProfilePasswordForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm({ resolver: yupResolver(schema) });

  const { showSnackbar } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [changesSaved, setChangesSaved] = useState<boolean>(false);
  const [showCurrentPassword, setShowCurrentPassword] =
    useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const onSubmit = async (resetPasswordData: any): Promise<void> => {
    setIsLoading(true);
    const { newPassword, currentPassword } = resetPasswordData;
    try {
      await AuthService.changePassword({
        password: newPassword,
        oldPassword: currentPassword,
      });

      showSnackbar('Password has been changed', 'success');
      setChangesSaved(true);
      setIsEditable(false);
    } catch (err: any) {
      setError('currentPassword', {
        type: 'manual',
        message: err.error,
      });

      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        background: colors.lightGrey,
        padding: '40px',
        width: {
          xs: '100%',
          sm: '100%',
          md: '70%',
          lg: '70%',
          xl: '70%',
        },
        borderRadius: '10px',
      }}
    >
      {isEditable ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ position: 'relative' }}
        >
          <Backdrop
            open={isLoading}
            sx={{
              borderRadius: '28px',
              position: 'absolute',
              backgroundColor: '#fff',
              opacity: '0.6 !important',
              zIndex: 3,
            }}
          >
            <CircularProgress color="primary" />
          </Backdrop>
          <Box>
            <Box sx={{ display: 'flex', width: '80%', marginBottom: '15px' }}>
              <Typography
                color=""
                variant="h2"
                sx={{
                  fontSize: '24px',
                  fontWeight: '600',
                  textAlign: 'start',
                }}
              >
                Change password
              </Typography>
            </Box>
            <Box>
              <Box
                width="100%"
                display="flex"
                flexDirection="column"
                rowGap="20px"
              >
                <ControlledTextField
                  control={control}
                  name="currentPassword"
                  label="Current password"
                  error={errors}
                  placeholder="Enter current password"
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '50%',
                    xl: '50%',
                  }}
                  type={showCurrentPassword ? 'text' : 'password'}
                  maxLength={256}
                  textFieldProps={{
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setShowCurrentPassword(!showCurrentPassword);
                            }}
                          >
                            {showCurrentPassword ? (
                              <SvgComponentSee />
                            ) : (
                              <SvgComponentHide />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <ControlledTextField
                  control={control}
                  name="newPassword"
                  label="New password"
                  error={errors}
                  placeholder="Enter new password"
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '50%',
                    xl: '50%',
                  }}
                  type={showNewPassword ? 'text' : 'password'}
                  maxLength={256}
                  textFieldProps={{
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setShowNewPassword(!showNewPassword);
                            }}
                          >
                            {showNewPassword ? (
                              <SvgComponentSee />
                            ) : (
                              <SvgComponentHide />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
                <ControlledTextField
                  control={control}
                  name="confirmPassword"
                  label="Confirm password"
                  error={errors}
                  placeholder="Confirm new password"
                  width={{
                    xs: '100%',
                    sm: '100%',
                    md: '50%',
                    lg: '50%',
                    xl: '50%',
                  }}
                  type={showConfirmPassword ? 'text' : 'password'}
                  maxLength={256}
                  textFieldProps={{
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => {
                              setShowConfirmPassword(!showConfirmPassword);
                            }}
                          >
                            {showConfirmPassword ? (
                              <SvgComponentSee />
                            ) : (
                              <SvgComponentHide />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: '40px',
            }}
          >
            <Button
              sx={{
                marginRight: '10px',
                background: '#eeeeee',
                color: 'black',
                width: '90px',
                borderRadius: '10px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#eeeeee',
                },
              }}
              onClick={() => setIsEditable(false)}
              type="button"
            >
              Cancel
            </Button>
            <Button
              sx={{
                background: colors.lightGreen,
                color: 'white',
                width: '90px',
                borderRadius: '10px',
                '&.MuiButtonBase-root:hover': {
                  bgcolor: '#1d4fbb',
                },
              }}
              type="submit"
            >
              Confirm
            </Button>
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              variant="h2"
              sx={{
                fontSize: '24px',
                fontWeight: '600',
                textAlign: 'start',
                marginBottom: '10px',
              }}
            >
              Password
            </Typography>
            <Typography
              sx={{
                fontSize: '14px',
                textAlign: 'start',
                color: colors.grey,
              }}
            >
              Change password regularly to keep your account secure
            </Typography>
          </Box>
          <Button
            sx={{
              background: colors.lightGreen,
              color: 'white',
              padding: '0 10px',
              borderRadius: '10px',
              height: '40px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#1d4fbb',
              },
            }}
            onClick={() => {
              reset();
              setIsEditable(true);
            }}
          >
            Change password
          </Button>
          <CustomModal
            showClose
            open={changesSaved}
            onClose={() => setChangesSaved(false)}
            maxWidth="500px"
          >
            <SaveSuccessModal />
          </CustomModal>
        </Box>
      )}
    </Box>
  );
};
