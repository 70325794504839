import { useState } from 'react';
import { HostingOpportunitiesTable } from 'components/Tables/HostingOpportunitiesTable';
import { HardwareOpportunitiesTable } from 'components/Tables/HardwareOpportunitiesTable';
import { Layout } from 'components/Layout';

export const Opportunities = () => {
  const [hardwareChange, setHardwareChange] = useState(false);

  return (
    <Layout>
      <HostingOpportunitiesTable
        hardwareChange={hardwareChange}
        setHardwareChange={setHardwareChange}
      />
      <HardwareOpportunitiesTable
        setHardwareChange={setHardwareChange}
        hardwareChange={hardwareChange}
      />
    </Layout>
  );
};
