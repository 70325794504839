import { Box, Button } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const SupplierType = ({ type }: any) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {type.map((item: any, index: number) => {
        return (
          <Box
            key={index}
            sx={{
              backgroundColor: item.color,
              borderRadius: '20px',
              padding: '2px 15px',
              marginRight: '10px',
            }}
          >
            {item.name}
          </Box>
        );
      })}
    </Box>
  );
};

export const AddHardwareOpportunityAction = ({ onClick }: any) => {
  return (
    <Button
      sx={{
        borderRadius: '6px',
        backgroundColor: colors.white,
        color: colors.lightGreen,
        border: `1px solid ${colors.lightGreen}`,
        fontSize: '10px',
        fontWeight: 600,
      }}
      onClick={onClick}
    >
      Add
    </Button>
  );
};
