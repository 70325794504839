import { Box } from '@mui/material';
import { Button } from '@mui/material';
import { colors } from 'shared/constants/theme';

export const SupplierType = ({ type, isHardware }: any) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {type === 'Supplier' ? (
        <Box
          sx={{
            backgroundColor: '#74e6c4',
            borderRadius: '20px',
            padding: '2px 15px',
            marginRight: '10px',
          }}
        >
          Host
        </Box>
      ) : null}
      {type === 'HardwareSupplier' || isHardware ? (
        <Box
          sx={{
            backgroundColor: '#7398e6',
            borderRadius: '20px',
            padding: '2px 15px',
            marginRight: '10px',
          }}
        >
          Hard
        </Box>
      ) : null}
    </Box>
  );
};

export const DownloadButton = (props: any) => {
  return (
    <Button
      onClick={props.onClick}
      size="small"
      variant="contained"
      color="secondary"
      sx={{
        borderRadius: 1.5,
        paddingX: 2.5,
        paddingY: 1,
        borderColor: `${colors.lightGreen} !important`,
        color: `${colors.lightGreen} !important`,
      }}
    >
      {props.buttonText ? props.buttonText : 'Download PDF'}
    </Button>
  );
};
