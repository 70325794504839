import { Box, IconButton, Input, InputAdornment } from '@mui/material';
import search from 'assets/images/admin/search.svg';

import './index.css';

interface SearchInputProps {
  setValue: undefined | ((value: string) => void);
  value: string | undefined;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchInput = ({ setValue, value }: SearchInputProps) => {
  return (
    <Input
      placeholder="Search"
      className="searchInput"
      sx={{
        border: '1px solid #E0E0E0',
        borderRadius: '10px',
        maxWidth: '264px',
        flexDirection: 'row-reverse',
        color: '#828282',
        padding: '4px 15px',
      }}
      onChange={(e) => setValue && setValue(e.target.value)}
      value={value}
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <IconButton>
            <Box
              component="img"
              sx={{
                cursor: 'pointer',
                height: 20,
                width: 20,
                maxHeight: { xs: 17, md: 17 },
                maxWidth: { xs: 17, md: 17 },
              }}
              alt="search icon"
              src={search}
            />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
