import { useMemo } from 'react';
import { makeUrl } from 'routes/makeUrl';
import { useAuth } from 'shared/hooks/useAuth';
import { routesMapping } from 'routes/mappings';
import { useLocation } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { useVerification } from 'shared/hooks/useVerification';
import { colors } from 'shared/constants/theme';

const LayoutNavigateBar = () => {
  const { user } = useAuth();
  // const { isNewUser, isVerified } = useVerification();
  const location = useLocation();
  const { customerId } = useParams();

  const operationLinks: Partial<Record<UserRole, string>> = {
    [UserRole.SUPPLIER]: routesMapping.supplier,
    [UserRole.CUSTOMER]: routesMapping.customerHome,
    [UserRole.SUBACCOUNT]: routesMapping.customerHome,
    [UserRole.AFFILIATE]: routesMapping.affiliateOperation,
    [UserRole.AFFILIATE_SUBACCOUNT]: customerId
      ? makeUrl(routesMapping.affiliateCustomerDetails, { customerId })
      : routesMapping.affiliateOperation,
    [UserRole.ADMIN]: customerId
      ? makeUrl(routesMapping.adminUser, { customerId })
      : routesMapping.adminManagement,
  };

  const userMenus = useMemo(() => {
    switch (user.role) {
      case UserRole.ADMIN:
        if (location.pathname.includes('customer-view'))
          return [
            {
              name: 'Home',
              link: makeUrl(routesMapping.adminViewCustomerHome, {
                customerId,
              }),
            },
            {
              name: 'DeFi',
              link: makeUrl(routesMapping.adminViewCustomerWallet, {
                customerId,
              }),
            },
            {
              name: 'Invoicing',
              link: makeUrl(routesMapping.adminViewCustomerInvoicing, {
                customerId,
              }),
            },
          ];
        return [
          {
            name: 'Operations',
            link: routesMapping.adminOperation,
          },
          {
            name: 'Management',
            link: routesMapping.adminManagement,
          },
          {
            name: 'Opportunities',
            link: routesMapping.adminOpportunities,
          },
          {
            name: 'Simulation',
            link: routesMapping.adminSimulation,
          },
        ];
      case UserRole.CUSTOMER:
        return [
          {
            name: 'Home',
            link: operationLinks[UserRole.CUSTOMER],
          },
          {
            name: 'DeFi',
            link: routesMapping.customerWallet,
          },
          {
            name: 'Invoicing',
            link: routesMapping.customerInvoicing,
          },
        ];
      case UserRole.SUBACCOUNT:
        return [
          {
            name: 'Home',
            link: operationLinks[UserRole.CUSTOMER],
          },
          {
            name: 'DeFi',
            link: routesMapping.customerWallet,
          },
          {
            name: 'Invoicing',
            link: routesMapping.customerInvoicing,
          },
        ];
      case UserRole.SUPPLIER:
        return [
          {
            name: 'Home',
            link: routesMapping.supplier,
          },
        ];
      case UserRole.AFFILIATE:
        if (location.pathname.includes('customer-view'))
          return [
            {
              name: 'Home',
              link: makeUrl(routesMapping.affiliateViewCustomerHome, {
                customerId,
              }),
            },
            {
              name: 'DeFi',
              link: makeUrl(routesMapping.affiliateViewCustomerWallet, {
                customerId,
              }),
            },
            {
              name: 'Invoicing',
              link: makeUrl(routesMapping.affiliateViewCustomerInvoicing, {
                customerId,
              }),
            },
          ];
        return [
          {
            name: 'Customers',
            link: customerId
              ? makeUrl(routesMapping.affiliateCustomerDetails, { customerId })
              : routesMapping.affiliateCustomers,
          },
          {
            name: 'Operations',
            link: operationLinks[UserRole.AFFILIATE],
          },
          {
            name: 'Simulation',
            link: routesMapping.affiliateSimulation,
          },
          {
            name: 'Team members',
            link: routesMapping.affiliateTeamMembers,
          },
        ];
      case UserRole.AFFILIATE_SUBACCOUNT:
        if (location.pathname.includes('customer-view'))
          return [
            {
              name: 'Home',
              link: makeUrl(routesMapping.affiliateViewCustomerHome, {
                customerId,
              }),
            },
            {
              name: 'DeFi',
              link: makeUrl(routesMapping.affiliateViewCustomerWallet, {
                customerId,
              }),
            },
            {
              name: 'Invoicing',
              link: makeUrl(routesMapping.affiliateViewCustomerInvoicing, {
                customerId,
              }),
            },
          ];

        return [
          {
            name: 'Operations',
            link: operationLinks[UserRole.AFFILIATE],
          },
          {
            name: 'Customers',
            link: routesMapping.affiliateCustomers,
          },
          {
            name: 'Simulation',
            link: routesMapping.affiliateSimulation,
          },
        ];
    }
  }, [user, location, customerId]);

  const selectedTab = userMenus?.find((menu) =>
    location.pathname.startsWith(menu.link || ''),
  );

  return (
    <>
      {userMenus?.map((menu) => {
        // if (isNewUser && !isVerified) return <></>;

        return (
          <Link
            key={menu.name}
            to={menu?.link || ''}
            style={{ textDecoration: 'none' }}
          >
            <Typography
              color={colors.lightGrey}
              sx={{
                padding: '5px 15px',
                border:
                  selectedTab!?.link === menu?.link
                    ? `0.5px solid ${colors.lightGreen}`
                    : '',
                borderRadius: '10px',
                '&:hover': {
                  cursor: 'pointer',
                },
                color: {
                  xs: selectedTab!?.link === menu?.link ? colors.white : '',
                  sm: selectedTab!?.link === menu?.link ? colors.white : '',
                  md: colors.white,
                  lg: colors.white,
                  xl: colors.white,
                },
                backgroundColor:
                  selectedTab!?.link === menu?.link ? colors.lightGreen : '',
              }}
            >
              {menu.name}
            </Typography>
          </Link>
        );
      })}
    </>
  );
};

export default LayoutNavigateBar;
