import { NormalCssProperties } from '@mui/material/styles/createMixins';
import { colors } from 'shared/constants/theme';

export const container = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
};

export const content: NormalCssProperties = {
  width: '100%',
  padding: '30px',
  margin: '0 20px',
  backgroundColor: 'white',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '9px',
  flexDirection: 'column',
};

export const formTitle = {
  fontSize: '24px',
  fontWeight: '600',
};

export const buttons = {
  marginTop: '23px',
};

export const submitButton = {
  background: colors.lightGreen,
  color: 'white',
  width: '150px',
  borderRadius: '10px',
  '&.MuiButtonBase-root:hover': {
    bgcolor: '#1d4fbb',
  },
};
