import { useCallback, useEffect, useMemo, useState } from 'react';
import { columns, subTableColumns } from './columns';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box, TableHead } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { WarningModal } from 'components/Modals/WarningModal';
import UsersService from 'shared/services/users.service';
import HostingService from 'shared/services/hosting.service';
import hardwareOpportunityService from 'shared/services/hardware-opportunity.service';
import { useNotification } from 'shared/hooks/useNotification';
import { AdminHostingForm } from 'components/Forms/AdminHostingForm';
import { NestedTable } from '../NestedTable';
import { AdminHardwareSupplierDetailsForm } from 'components/Forms/AdminHardwareSupplierDetailsForm';
import { TableHeaderOptions } from 'shared/utils/enum/tableEnums';
import { AddHardwareOpportunityAction } from './cells';

export const HostingOpportunitiesTable = ({
  hardwareChange,
  setHardwareChange,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [supplierAccounts, setSupplaerAccounts] = useState([]);
  const [hostingData, setHostingData] = useState<any[] | null>(null);
  const [hostingOpps, setHostingOpps] = useState<any[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [delHostingID, setDelHostingID] = useState<string>('');
  const [delHardwareOppID, setDelHardwareOppID] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [showDetailsEditModal, setShowDetailsEditModal] = useState(false);
  const [showHardwareOpportunityModal, setShowHardwareOpportunityModal] =
    useState(false);
  const [hardwareOppData, setHardwareOppData] = useState<any[] | null>(null);
  const [selectedHostingOpportunity, setSelectedHostingOpportunity] =
    useState();
  const { showSnackbar } = useNotification();

  const fetchAccount = async (name = '') => {
    setHostingOpps(null);
    try {
      const response = await HostingService.getHostings({
        pageNumber: currentPage,
        limit: rowsPerPage,
        name,
      });
      const { data, totalPages } = response;

      setHostingOpps(data);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const fetchSuppliers = async () => {
    setSupplaerAccounts([]);
    try {
      const response = await UsersService.getAllSuppliers();

      const { users } = response;

      const hostingSuppliers = users.filter(
        (supplier: any) => supplier.role === 'Supplier',
      );

      setSupplaerAccounts(hostingSuppliers);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const handleEdit = (hosting: any): void => {
    setShowEditModal(true);
    setHostingData(hosting);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage, rowsPerPage, hardwareChange]);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const connectedHardware = useMemo(() => {
    const selectedHostingOpportunity = hostingOpps?.find(
      (opp) => opp.id === delHostingID,
    );

    return selectedHostingOpportunity
      ? selectedHostingOpportunity.hardwareOpportunity
      : [];
  }, [delHostingID, hostingOpps]);

  const handleDeleteHosting = useCallback(async () => {
    try {
      if (delHostingID) {
        await HostingService.deleteHosting(delHostingID);
        fetchAccount();
        setDelHostingID('');
        showSnackbar('Successfully Deleted', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [delHostingID]);

  const handleEditDetails = (
    hardwareOpportunity: any,
    hostingOpportunity: any,
  ): void => {
    setSelectedHostingOpportunity(hostingOpportunity);
    setShowDetailsEditModal(true);
    setHardwareOppData(hardwareOpportunity);
  };

  const handleDeleteHardwareOpp = useCallback(async () => {
    setIsLoading(true);
    try {
      if (delHardwareOppID) {
        await hardwareOpportunityService.deleteHardwareOpportunity(
          delHardwareOppID,
        );
        fetchAccount();
        setDelHardwareOppID('');
        setHardwareChange(!hardwareChange);
        showSnackbar('Successfully Deleted', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [delHardwareOppID]);

  return (
    <Box marginY="30px">
      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="750px"
        backdropOpen={isLoading}
      >
        <AdminHostingForm
          setShowModal={setShowModal}
          onFinish={fetchAccount}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
        />
      </CustomModal>
      <CustomModal
        open={showHardwareOpportunityModal}
        onClose={() => setShowHardwareOpportunityModal(false)}
        maxWidth="750px"
      >
        <AdminHardwareSupplierDetailsForm
          setShowModal={setShowHardwareOpportunityModal}
          onFinish={fetchAccount}
          hardware={hardwareOppData}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
          setHardwareChange={setHardwareChange}
          hardwareChange={hardwareChange}
          hostingOpportunity={selectedHostingOpportunity}
        />
      </CustomModal>
      <NestedTable
        tableName="Hosting Opportunities"
        items={hostingOpps}
        columns={columns(
          fetchAccount,
          setIsLoading,
          hardwareChange,
          setHardwareChange,
        )}
        setShowModal={setShowModal}
        handleEdit={handleEdit}
        handleSubTableEdit={handleEditDetails}
        showEnd={true}
        setCurrentPage={setCurrentPage}
        onRowDelete={setDelHostingID}
        onSubRowDelete={setDelHardwareOppID}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        fetchData={fetchAccount}
        searchable={true}
        subTableColumns={subTableColumns}
        nestingKey="hardwareOpportunity"
        addButtonText="Add Opportunity"
        subTableHeaderOption={TableHeaderOptions.LIGHT}
        subTableAction={(hostingOpportunity: any) => (
          <AddHardwareOpportunityAction
            onClick={() => {
              setShowHardwareOpportunityModal(true);
              setSelectedHostingOpportunity(hostingOpportunity);
            }}
          />
        )}
      />
      <CustomModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        maxWidth="750px"
        backdropOpen={isLoading}
      >
        <AdminHostingForm
          setShowModal={setShowEditModal}
          editData={hostingData}
          onFinish={fetchAccount}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
        />
      </CustomModal>
      <CustomModal
        open={showDetailsEditModal}
        onClose={() => setShowDetailsEditModal(false)}
        maxWidth="750px"
      >
        <AdminHardwareSupplierDetailsForm
          setShowModal={setShowDetailsEditModal}
          editData={hardwareOppData}
          onFinish={fetchAccount}
          hardware={hardwareOppData}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
          setHardwareChange={setHardwareChange}
          hardwareChange={hardwareChange}
          hostingOpportunity={selectedHostingOpportunity}
        />
      </CustomModal>
      <CustomModal
        open={!!delHostingID}
        onClose={() => setDelHostingID('')}
        backdropOpen={isLoading}
      >
        <Box className="container">
          {!connectedHardware.length ? (
            <DeleteModal
              title="Hosting opportunity"
              onClick={handleDeleteHosting}
              onCancel={() => setDelHostingID('')}
            />
          ) : (
            <WarningModal
              onCancel={() => setDelHostingID('')}
              devices={connectedHardware}
            />
          )}
        </Box>
      </CustomModal>
      <CustomModal
        open={!!delHardwareOppID}
        onClose={() => setDelHardwareOppID('')}
        backdropOpen={isLoading}
      >
        <Box className="container">
          <DeleteModal
            title="Hardware opportunity"
            onClick={handleDeleteHardwareOpp}
            onCancel={() => setDelHardwareOppID('')}
          />
        </Box>
      </CustomModal>
    </Box>
  );
};
