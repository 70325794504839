import { colors } from 'shared/constants/theme';

export const modalContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  alignItems: 'center',
  rowGap: '20px',
};

export const titleStyles = {
  fontSize: '24px',
  fontWeight: '500',
  color: colors.black,
};

export const textStyles = {
  textAlign: 'center',
  fontSize: '16px',
  fontWeight: '400',
  color: 'gray',
  width: '80%',
};

export const mailStyles = {
  fontSize: '16px',
  fontWeight: '400',
  color: '#1D4FBB',
  textDecoration: 'underline',
  marginLeft: '5px',
};
