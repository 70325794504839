import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { StyledTableCell } from './styles';
import { colors } from 'shared/constants/theme';
import { TableHeaderOptions } from 'shared/utils/enum/tableEnums';

export const EnhancedTableHead = (props: any) => {
  const { order, orderBy, onRequestSort, columns, headerOption } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      {columns.map((column: any) => (
        <StyledTableCell
          key={column.id}
          sortDirection={orderBy === column.id ? order : false}
          style={{
            minWidth: column.minWidth,
            width: column?.width,
            borderBottom: '1px solid #E0E0E0',
            fontSize: '14px',
            fontWeight: '600',
            backgroundColor:
              headerOption === TableHeaderOptions.LIGHT
                ? colors.white
                : colors.lightGreen,
          }}
          align={column.align}
        >
          {column.sortable === undefined || column.sortable ? (
            <TableSortLabel
              style={{
                color:
                  headerOption === TableHeaderOptions.LIGHT
                    ? colors.lightGreen
                    : colors.white,
              }}
              active
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <Box
              style={{
                color:
                  headerOption === TableHeaderOptions.LIGHT
                    ? colors.lightGreen
                    : colors.white,
              }}
            >
              {column.label}
            </Box>
          )}
        </StyledTableCell>
      ))}
    </>
  );
};
