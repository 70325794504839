import { Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import CustomersTable from 'components/Tables/CustomersTable';
import { colors } from 'shared/constants/theme';

export const Customers = () => {
  return (
    <Box>
      <SectionHeading
        name="Customers"
        sx={{
          marginBottom: '30px',
          color: colors.black,
        }}
      />
      <CustomersTable />
    </Box>
  );
};
