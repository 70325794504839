import { Box } from '@mui/material';

export const columns = (
  fetchAccount: any,
  setIsLoading: any,
  hardwareChange: any,
  setHardwareChange: any,
) => {
  return [
    {
      id: 'name',
      label: 'Opportunity name',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'username',
      label: 'Supplier',
      minWidth: 100,
      align: 'left',
      render: (hosting: any) => {
        return <Box>{hosting.username}</Box>;
      },
    },
    {
      id: 'location',
      label: 'Location',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'electricityCost',
      label: 'Electricity cost, $ / KWh',
      minWidth: 100,
      align: 'left',
      render: (hosting: any) => {
        return (
          <Box>
            $
            {hosting.electricityCost.toLocaleString('en-EN', {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })}
          </Box>
        );
      },
    },
    {
      id: 'priority',
      label: 'Priority',
      minWidth: 100,
      align: 'left',
      render: (hosting: any) => {
        return <Box>{hosting.priority ? '✅' : '-'}</Box>;
      },
    },
  ];
};

export const subTableColumns = [
  {
    id: 'device',
    label: 'DEVICE NAME',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props?.hardware?.device;
    },
  },
  {
    id: 'coin',
    label: 'COIN',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props?.hardware?.currency;
    },
  },
  {
    id: 'supplier',
    label: 'SUPPLIER',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props.supplier.username;
    },
  },
  {
    id: 'hashrate',
    label: 'HASHRATE',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props?.hardware?.hashrate;
    },
  },
  {
    id: 'electricity',
    label: 'POWER, WATT',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => {
      return props.hardware?.electricity;
    },
  },
  {
    id: 'totalPrice',
    label: 'TOTAL PRICE, $',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => {
      return `$${props.totalPrice.toLocaleString('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    },
  },
];
