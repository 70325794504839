import { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  TableCell,
  Typography,
} from '@mui/material';
import { Progress } from 'components/Progress';
import { PropsTypes } from './types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SearchInput } from 'components/SearchInput';
import { useDebounce } from 'shared/hooks/useDebounce';
import {
  AddCircleOutline,
  DeleteOutline,
  EditOutlined,
} from '@mui/icons-material';
import { getComparator, stableSort } from '../RedesignedTable/sorting';
import { StyledTableCell, StyledTableRow } from './styles';
import { EnhancedTableHead } from './SortableTableHead';
import { colors } from 'shared/constants/theme';
import './index.css';

const rowItems: number[] = [5, 10, 15, 20, 25];

export const BaseTable = (props: PropsTypes) => {
  const {
    tableName,
    items,
    columns,
    setShowModal,
    handleEdit,
    onRowDelete,
    totalPages,
    currentPage,
    setCurrentPage,
    rowsPerPage,
    setRowsPerPage,
    searchable,
    fetchData,
    show,
    actions,
    rowColor,
    paginated = true,
    addButton = true,
    isEditable = true,
    addButtonText,
  } = props;

  const [value, setValue] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const debouncedQuery = useDebounce(value, 500);
  const pageNumbers =
    totalPages ?? Math.ceil(items?.length / (rowsPerPage ?? 0));

  const handleChange = (event: SelectChangeEvent) => {
    setRowsPerPage?.(event.target.value as unknown as number);
  };
  const onSearchChange = async (value: string) => {
    setValue(value);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const inputValue = (event.target as HTMLInputElement).value.trim();
      setSearch(inputValue);
    }
  };

  const [order, setOrder] = useState<any>('asc');
  const [orderBy, setOrderBy] = useState<any>('');

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (search) fetchData && fetchData(search);
  }, [search]);

  useEffect(() => {
    if (debouncedQuery !== search || !debouncedQuery)
      fetchData && fetchData(debouncedQuery);
  }, [debouncedQuery, currentPage, rowsPerPage, search]);

  useEffect(() => {
    if (
      currentPage &&
      setCurrentPage &&
      pageNumbers < currentPage &&
      pageNumbers != 0
    ) {
      setCurrentPage(pageNumbers);
    }
  }, [pageNumbers, currentPage]);

  const visibleRows = useMemo(
    () =>
      items && items.length > 0
        ? stableSort(items, getComparator(order, orderBy))
        : [],
    [items, order, orderBy],
  );

  return (
    <Box className="tableWrapper">
      <Paper
        sx={{
          bgcolor: '#fff',
          color: '#131613',
          borderRadius: '20px',
        }}
      >
        {searchable && (
          <Box sx={{ marginBottom: '30px', borderRadius: '0px' }}>
            <SearchInput
              value={value}
              setValue={onSearchChange}
              onKeyDown={handleKeyPress}
            />
          </Box>
        )}
        {tableName && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '30px',
            }}
          >
            <span
              style={{ textAlign: 'left', fontSize: '24px', fontWeight: 600 }}
            >
              {tableName}
            </span>
            {show && (
              <Box
                display="flex"
                columnGap={2}
                sx={{
                  flexDirection: { xs: 'column', sm: 'row' },
                  rowGap: { xs: '10px', sm: '0px' },
                }}
              >
                {actions}
                {addButton ? (
                  <Box
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#ffffff',
                      border: `1px solid ${colors.lightGreen}`,
                      borderRadius: '20px !important',
                      color: colors.black,
                      padding: '5px 15px',
                    }}
                    className="addAdminStyles"
                    onClick={() => setShowModal && setShowModal(true)}
                  >
                    <AddCircleOutline
                      sx={{
                        cursor: 'pointer',
                        marginRight: '10px',
                        height: 20,
                        width: 20,
                        borderRadius: 20,
                      }}
                    />
                    {addButtonText ? addButtonText : 'Add'}
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            )}
          </div>
        )}
        <Box
          sx={{
            width: '100%',
            overflow: 'hidden',
            borderRadius: '0px',
            marginBottom: 0,
          }}
        >
          <TableContainer sx={{ marginBottom: 0, padding: 0 }}>
            <Table
              stickyHeader
              sx={{
                border: '1px solid #E0E0E0',
                borderRadius: '10px',
                borderSpacing: '0',
                padding: '0px 20px',
                paddingBottom: !items ? '20px' : '0px',
                marginBottom: 0,
              }}
            >
              <TableHead>
                <StyledTableRow>
                  {show && (
                    <StyledTableCell
                      align="center"
                      style={{ minWidth: 100, color: '#fff' }}
                    >
                      Actions
                    </StyledTableCell>
                  )}
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    columns={columns}
                  />
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!items ? (
                  <Progress className="center" size={24} />
                ) : !items?.length ? (
                  <Box className="center">EMPTY CONTENT</Box>
                ) : (
                  items?.length &&
                  visibleRows?.map((row: Record<string, any>) => {
                    const color =
                      (rowColor && rowColor(row)) || colors.lightGreen;

                    return (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:hover': {
                            td: {
                              borderColor: `${color} !important`,
                            },
                          },
                          '& .MuiButton-contained': {
                            backgroundColor: 'transparent',
                            color: '#828282',
                            border: '1px solid #828282',
                            boxShadow: 'none',
                          },
                        }}
                      >
                        {show && (
                          <StyledTableCell align="center">
                            {isEditable ? (
                              <EditOutlined
                                onClick={() => {
                                  handleEdit && handleEdit(row);
                                }}
                                sx={{
                                  color: 'secondary.main',
                                  cursor: 'pointer',
                                  fontSize: 25,
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            <DeleteOutline
                              sx={{
                                color: 'secondary.main',
                                cursor: 'pointer',
                                fontSize: 25,
                              }}
                              onClick={() => {
                                onRowDelete && onRowDelete(row.id);
                              }}
                            />
                          </StyledTableCell>
                        )}
                        {columns.map((column: any) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align="left">
                              {column.render
                                ? column.render(row)
                                : value !== undefined || value !== null
                                  ? value
                                  : '-'}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {paginated ? (
            <Box className="pagination">
              <Box
                sx={{
                  color: '#131613',
                  fontSize: {
                    xs: '10px',
                    sm: '10px',
                    md: '14px',
                    lg: '14px',
                  },
                }}
              >
                Showing {currentPage}-{pageNumbers} of {items?.length ?? 0}{' '}
                results
              </Box>
              <Pagination
                siblingCount={1}
                boundaryCount={1}
                count={pageNumbers}
                page={currentPage}
                onChange={(_, num) => setCurrentPage?.(num)}
                sx={{
                  '& .Mui-selected': {
                    bgcolor: `${colors.lightGreen} !important`,
                    color: '#fff',
                    borderRadius: '6px',
                  },
                  '& .Mui-disabled': {
                    display: 'none',
                  },
                }}
              />
              <Box sx={{ minWidth: 120 }}>
                <Typography sx={{ color: '#131613', fontSize: '14px' }}>
                  Showing:{' '}
                </Typography>
                <FormControl fullWidth>
                  <Select
                    value={`${rowsPerPage}`}
                    label="Age"
                    onChange={handleChange}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    }}
                  >
                    {rowItems.map((rowItem: number) => (
                      <MenuItem value={rowItem} key={rowItem}>
                        {rowItem}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
