import { useMemo } from 'react';
import { Box } from '@mui/material';
import { Statistics } from 'components/Statistics';
import { SectionHeading } from 'components/SectionHeading';
import { LiveUtcDateTime } from 'components/LiveUtcDateTime';

export const AffiliateCustomerOperations = (props: any) => {
  const { upfrontTotal, totalSales } = props;

  const statisticsData = useMemo(
    () => [
      {
        title: 'Total sales',
        value:
          totalSales || totalSales === 0
            ? `$${totalSales.toLocaleString('us-En', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : null,
        currency: 'USD',
      },
      {
        title: 'Total upfront payments',
        value:
          upfrontTotal || upfrontTotal === 0
            ? `$${upfrontTotal.toLocaleString('us-En', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : null,
        currency: 'USD',
      },
    ],
    [upfrontTotal, totalSales],
  );

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <SectionHeading
          sx={{
            color: '#fff',
          }}
          name="Your sales achievements"
        />
        <LiveUtcDateTime />
      </Box>
      <Statistics statistics={statisticsData} />
    </>
  );
};
