import { useState } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Delete, FileUploadOutlined } from '@mui/icons-material';
import * as yup from 'yup';
import hardwareService from 'shared/services/hardware.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { VisuallyHiddenInput } from 'components/VisuallyHiddenInput';
import FilesService from 'shared/services/files.service';
import { container, content, formTitle } from '../styles';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import { colors } from 'shared/constants/theme';
import { currencies } from 'shared/utils/currency';

export const AdminHardwareForm = ({
  setShowModal,
  editData,
  onFinish,
  setIsLoading,
}: any): JSX.Element => {
  const [file, setFile] = useState<any>();
  const [editDataFile, setEditDataFile] = useState<any>(
    editData?.image ? editData?.image : null,
  );
  const { showSnackbar } = useNotification();

  const handleTransform = (value: any, originalValue: any) =>
    typeof originalValue === 'string' && !originalValue.trim() ? null : value;

  const schema = yup.object().shape({
    device: yup.string().required('Device is a required field'),
    hashrate: yup
      .number()
      .typeError('Installation cost must be a number')
      .required('Installation cost is a required field')
      .transform(handleTransform),
    electricity: yup
      .number()
      .typeError('Electricity cost must be a number')
      .required('Electricity cost is a required field')
      .transform(handleTransform),
    lifespan: yup
      .number()
      .typeError('Expected lifespan must be a number')
      .required('Expected lifespan is a required field')
      .transform(handleTransform),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const onSubmit: any = async (hardwareData: any): Promise<void> => {
    setIsLoading(true);
    try {
      const payload = {
        ...hardwareData,
        priceFrom: '15',
      };

      if (file) {
        const fileFormData = new FormData();
        fileFormData.append('file', file);
        const { fileName } = await FilesService.uploadFile(fileFormData);

        payload.image = fileName;
      }

      if (editData) {
        await hardwareService.updateHardware(editData.id, {
          ...hardwareData,
          image: payload.image ? payload.image : editData.image,
        });
        showSnackbar('Successfully Edited', 'success');
      } else {
        await hardwareService.createHardware(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
      setIsLoading(false);
    }
  };

  const handleInvalidKeyPress = (e: any) => {
    if (e.key === '-' || e.key === '+' || e.key === '.' || e.key === ',') {
      e.preventDefault();
    }
  };

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>
          {editData ? 'Edit' : 'Create'} Device
        </Typography>
        <Typography
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            fontSize: '20px',
            fontWeight: '500',
          }}
        >
          Device details
        </Typography>
        <Box width="100%" display="flex" flexDirection="column" rowGap="20px">
          <ControlledTextField
            control={control}
            defaultValue={editData?.device ?? ''}
            name="device"
            label="Device name"
            error={errors}
            width="100%"
          />
          <Box sx={{ marginBottom: '20px', width: '100%' }}>
            <ControlledSelect
              control={control}
              name="currency"
              defaultValue={editData?.currency ? editData?.currency : 'Bitcoin'}
              label="Cryptocurrency"
              error={errors}
              options={currencies}
            />
          </Box>
          <Box
            sx={{ width: '100%' }}
            display="flex"
            flexDirection="column"
            columnGap={0.5}
            rowGap={0.5}
            marginY={0.5}
          >
            <Box
              display="flex"
              justifyContent="flex-start"
              columnGap={0.5}
              rowGap={0.5}
              marginY={0.5}
              width="100%"
            >
              <Typography
                fontWeight="500"
                fontSize="16px"
                lineHeight="15px"
                color="#666666"
                marginTop="10px"
              >
                Device image
              </Typography>
            </Box>
            <Box
              sx={{
                border: '1px dashed #d9d9d9',
                borderRadius: '8px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                sx={{
                  bgcolor: 'transparent',
                  color: colors.lightGreen,
                  border: '1px solid #d9d9d9',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                    boxShadow: 'none',
                  },
                }}
                component="label"
                variant="contained"
                startIcon={
                  <FileUploadOutlined sx={{ color: colors.lightGreen }} />
                }
              >
                Upload
                <VisuallyHiddenInput
                  onChange={(e) => setFile(e.target.files![0])}
                  accept=".jpg, .jpeg, .png"
                  type="file"
                />
              </Button>
              <Typography
                fontWeight="500"
                fontSize="16px"
                lineHeight="15px"
                color="#666666"
                marginTop="10px"
              >
                Upload device image
              </Typography>
              {file && (
                <Box display="flex" gap={1} alignItems="center" mt={1}>
                  {file.name.length > 18
                    ? `${file.name.substring(0, 18)}...`
                    : file.name}
                  <IconButton
                    onClick={() => setFile(null)}
                    aria-label="delete"
                    sx={{
                      '&:hover': {
                        background: 'white !important',
                      },
                    }}
                  >
                    <Delete sx={{ color: colors.lightGreen }} />
                  </IconButton>
                </Box>
              )}
              {editDataFile && !file && (
                <Box display="flex" gap={1} alignItems="center" mt={1}>
                  {editData?.image > 18
                    ? `${editData?.image.substring(0, 18)}...`
                    : editData?.image}
                  <IconButton
                    onClick={() => setEditDataFile(null)}
                    sx={{
                      '&:hover': {
                        background: 'white !important',
                      },
                    }}
                  >
                    <Delete sx={{ color: colors.lightGreen }} />
                  </IconButton>
                </Box>
              )}
            </Box>
            <ControlledTextField
              control={control}
              defaultValue={editData?.lifespan ?? ''}
              name="lifespan"
              label="Expected lifespan"
              error={errors}
              width="100%"
              type="number"
              onKeyDown={handleInvalidKeyPress}
            />
          </Box>
          <Box display="flex" columnGap="20px">
            <ControlledTextField
              control={control}
              defaultValue={editData?.hashrate ?? ''}
              name="hashrate"
              label="Hashrate, TH/s"
              error={errors}
              width="50%"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.electricity ?? ''}
              name="electricity"
              label="Device electric power, W"
              error={errors}
              width="50%"
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '20px',
          }}
        >
          <Button
            sx={{
              marginRight: '10px',
              background: '#eeeeee',
              color: 'black',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#eeeeee',
              },
            }}
            onClick={() => setShowModal(false)}
            type="button"
          >
            Cancel
          </Button>
          <Button
            sx={{
              background: colors.lightGreen,
              color: 'white',
              width: '90px',
              borderRadius: '10px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: '#1d4fbb',
              },
            }}
            type="submit"
          >
            {editData ? 'Save' : 'Create'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
